import { debugLog } from './debug';

export type GoogleParams = {
  measurementId: string;
  clientId: string;
  sessionId: string;
  ts: string;
};

export async function getGoogleSessionParams() {
  debugLog(`Trying Getting Params`);
  if (window.google_tag_manager || window.google_tag_data) {
    debugLog(`Found Google Window Object:  window.google_tag_data`);
    const measurementId = Object.keys(window.google_tag_manager).find(i => i.startsWith('G-'));
    debugLog(`Found MeasurementId`, measurementId);

    if (measurementId) {
      if (!window.dataLayer) window.dataLayer = [];

      if (!window.gtag) {
        window.gtag = function() {
          window.dataLayer.push(arguments);
        };
      }

      if (window.gtag) {
        debugLog(`Found Google Window Object:  window.gtag`);
        try {
          const [clientId, sessionId] = await Promise.all([
            getGtagValue(measurementId, 'client_id'),
            getGtagValue(measurementId, 'session_id')
          ]);

          if (clientId && sessionId) {
            const googleParams: GoogleParams = {
              measurementId,
              clientId,
              sessionId,
              ts: Date.now().toString()
            };
            debugLog(`Generated Google Params`, googleParams);
            return googleParams;
          }
        } catch (error) {
          window.GLAppInsights.trackException({
            exception: new Error(`Failed to retrieve Google session parameters: ${error}`)
          });

          return {};
        }
      }
    }
  }
  return {};
}

const getGtagValue = async (
  measurementId: string,
  fieldName: string
): Promise<string | undefined> => {
  return new Promise(resolve => {
    window.gtag('get', measurementId, fieldName, resolve);
  });
};
