/**
 * Little helper function that given a url will do a GET request and return the parsed JSON.
 * @param {string} url - url where to fetch the resource.
 * @returns {Promise<any>} a promise with the json or reject if the request or parse failed.
 */
interface Options {
  withCredentials?: boolean;
}

export interface Response {
  ok: boolean;
  status: number;
  statusText: string;
  data: string;
  json: <T>() => T;
  headers: string;
}

export function get(url: string, options: Options = {}): any {
  return new Promise<Response>((resolve, reject) => {
    const request = new XMLHttpRequest();

    request.onreadystatechange = () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        if (request.status >= 400) {
          reject(request.status);
        } else {
          try {
            const response = JSON.parse(request.responseText || '{}');
            resolve(response);
          } catch (e) {
            reject(e);
          }
        }
      }
    };
    request.open('GET', url, true);

    if (options.withCredentials) {
      request.withCredentials = true;
    }

    request.send();
  });
}
